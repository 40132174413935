import React from "react";
import fb from "../assets/fb.svg";
import ig from "../assets/ig.svg";
import yt from "../assets/yt.svg";
import twitter from "../assets/twitter.svg";
import wtsp from "../assets/wtsp.svg";
import collage from "../assets/collage.jpg";
import lp1 from "../assets/little-boy-with-hand-full-paint-covering-his-face.jpg";
import lp2 from "../assets/children-group-reading-books.jpg";
import lp3 from "../assets/adorable-schoolgirl-smiling-camera.jpg";
import lp4 from "../assets/portrait-boy-with-hands-thumbs-up-classroom.jpg";
import footerImgRight from "../assets/footer-right.jpg";
import footerImgLeft from "../assets/footer-left.jpg";
import { Link } from "react-router-dom";

const learningProcess = [
  {
    title: "PLAYWAY",
    text: "AVNians join our school at very early age of 2 years",
    img: lp1,
  },
  {
    title: "NURSERY",
    text: "Where Reading and Learning of Academics Begins",
    img: lp2,
  },
  {
    title: "KINDERTARTEN",
    text: "Skill & learning becomes so easy to beat high classes",
    img: lp3,
  },
  {
    title: "GRADE 1st to 8th",
    text: "Attitude & Behaviour Development brings here to meet high school need",
    img: lp4,
  },
];

const Home = () => {
  return (
    <div>
      <div className="flex flex-col items-center gap-4 px-28">
        <div className="text-5xl w-full text-center font-bold font-serif tracking-widest leading-normal mix-blend-darken">
          An English Medium Co-Educational School
        </div>
        <div className="text-4xl w-full text-center font-bold font-serif tracking-widest leading-normal mix-blend-darken">
          {/* BEING INTELLIGENT
          <br />
          AND
          <br />
          TALENTED STUDENTS */}
          BEING INTELLIGENT AND TALENTED STUDENTS
        </div>
        <div className="text-3xl">
          We pride ourselves in teaching and developing Bharat next generation
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center px-28">
        <div className="text-2xl">Explore Now</div>
        <img src={collage} alt="AVN Logo" className="w-3/5 mx-auto mt-6" />
      </div>

      <div className="mt-16 px-28" id="overview">
        <div className="text-4xl font-semibold underline text-center">
          An Overview
        </div>
        <div className="text-3xl mt-4">AVNians</div>

        <div className="">
          The sole moto of our establishment - AVN Play School was to create
          with an aim to fire the imagination of AVNians (student). The academic
          environment of our school provides guidance of knowledge with love and
          happiness by inculcating weekend activities on regular basis.
        </div>
      </div>

      <div className="mt-16 px-28" id="learning-process">
        <div className="text-4xl text-center mb-6 font-semibold underline">
          The Learning Process
        </div>
        <div className="flex justify-between gap-8">
          {learningProcess?.map((item, index) => (
            <div key={index} className="flex flex-col items-center gap-2">
              <h2 className="text-3xl">{item?.title}</h2>
              <h2>{item?.text}</h2>
              <img
                src={item?.img}
                className="w-80 h-60 object-cover"
                alt={item?.title}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-16 px-28">
        <div className="text-4xl mb-6 font-semibold underline text-center">
          Gallery
        </div>
        <div className="flex justify-between gap-6">
          <div className="flex flex-col gap-2 items-center">
            <div className="flex gap-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/avnplayschool"
              >
                <img
                  src={fb}
                  alt="Facebook"
                  className="w-32 cursor-pointer"
                  href="https://www.facebook.com/avnplayschool"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/avn.play.school"
              >
                <img src={ig} alt="Instagram" className="w-32 cursor-pointer" />
              </a>
            </div>
            <div>Images</div>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@avnplayschool"
            >
              <img src={yt} alt="YouTube" className="w-32 cursor-pointer" />
            </a>
            <div>Videos</div>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/Avnplayschool"
            >
              <img
                src={twitter}
                alt="Twitter"
                className="w-32 scroll-smooth render cursor-pointer"
              />
            </a>
            <div>Achievements</div>
          </div>
        </div>
        {/* <div className="text-4xl">Images → View All</div>
        <div className="text-4xl">Videos → View All</div>
        <div className="text-4xl">Acheivement → View All</div> */}
      </div>

      <div className="flex mt-16">
        <div className="relative">
          <div className="absolute">
            <div className="flex flex-col w-[80vw] px-8">
              <div className="text-white px-16 py-8">
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <div>
                      <a href="#header">Home</a>
                    </div>
                    <div>
                      <a href="#overview">Overview</a>
                    </div>
                    <div>
                      <a href="#learning-process">Learning Process</a>
                    </div>
                    <div>
                      <Link to="/secretary-vision-&-mission">
                        Secretary - Vision & Mission
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>Nandpuri Road</div>
                    <div>Barkat Nagar</div>
                    <div>8:00 a.m. – 2:00 p.m.</div>
                    <div className="mt-2">
                      <div>For Admission Eligibility</div>
                      <div className="flex items-center gap-2">
                        Contact Us
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://wa.me/+919828228282"
                        >
                          <img src={wtsp} alt="Whatsapp" className="w-6" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>Learnings</div>
                    <div>Playway</div>
                    <div>Nursery</div>
                    <div>LKG</div>
                    <div>UKG</div>
                    <div>Primary School Classes</div>
                    <div>Middle School Classes</div>
                  </div>
                </div>
              </div>
              <div className="text-center text-black font-semibold mt-8">
                Copyright@2024 | AVN Play School | Powered by AVNSS
              </div>
            </div>
          </div>
          <img
            src={footerImgLeft}
            alt={footerImgLeft}
            className="w-[80vw] h-[308px] object-center"
          />
        </div>

        <img
          src={footerImgRight}
          alt={footerImgRight}
          className="w-[20vw] h-[308px] object-center"
        />
      </div>
    </div>
  );
};

export default Home;
