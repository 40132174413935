import React from "react";

import logo from "../assets/logo.png";
import { Sidebar } from "./sidebar/Sidebar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="flex justify-between items-center px-28 py-2" id="header">
      <div>
        <div className="text-[#be4348] text-4xl font-bold font-serif border-l-2 border-[#5c6d63]">
          <h1 className="border-b-2 border-[#5c6d63] pl-2">AVN</h1>
          <h1 className="pl-2 text-2xl">PLAY SCHOOL</h1>
        </div>

        <h2 className="text-xl mt-2 font-mono">Play Way - Class 8th</h2>
      </div>
      <Link to="/home">
        <img src={logo} alt="AVN Logo" className="w-32 cursor-pointer" />
      </Link>
      <div>
        <h2 className="border border-1 border-dashed p-2 border-black text-lg">
          A Unit of AVNSS Group of School
        </h2>
        <div className="text-xl text-center mt-2 font-mono">Call Us @ +919828228282</div>
      </div>
      <Sidebar />
    </div>
  );
};

export default Header;
