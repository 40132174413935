import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from "./components/Home";
import Blog from "./components/Blog";
import Highlights from "./components/Highlights";
import Contact from "./components/Contact";
import Header from "./components/Header";
import bgImg1 from "./assets/bg.jpg";

import SecretaryVision from "./components/SecretaryVision";

const Layout = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImg1})`,
      }}
      className="bg-fixed bg-center bg-no-repeat bg-cover mix-blend-difference overflow-clip"
    >
      <div className="h-[120px]">
        <div className="sticky top-0 left-0 w-full z-10">
          <Header />
        </div>
      </div>
      <div className="h-[calc(100vh-120px)] overflow-y-scroll pt-8">
        <Outlet />
      </div>
    </div>
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="highlights" element={<Highlights />} />
            <Route path="contact" element={<Contact />} />
            <Route
              exact
              path="secretary-vision-&-mission"
              element={<SecretaryVision />}
            />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
