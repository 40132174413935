// import {
//   Box,
//   Divider,
//   Drawer,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
// } from "@mui/material";
// // import { InboxIcon, MailIcon } from "@mui/icons-material";

// import React from "react";

// export const Sidebar = ({ isMenuOpen, toggleMenu }) => {
//   const tabs = ["Home", "AVNSS Edges", "Academics", "Activites", "Contact"];

//   return (
//     <Drawer
//       anchor="left"
//       open={isMenuOpen}
//       onClose={toggleMenu(false)}
//     >
//       <Box
//         sx={{ width: 250 }}
//         role="presentation"
//         onClick={toggleMenu(false)}
//         // onKeyDown={toggleDrawer(anchor, false)}
//       >
//         <List>
//           {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
//             <ListItem key={text} disablePadding>
//               <ListItemButton>
//                 <ListItemIcon>
//                   {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
//                 </ListItemIcon>
//                 <ListItemText primary={text} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//         <Divider />
//         <List>
//           {["All mail", "Trash", "Spam"].map((text, index) => (
//             <ListItem key={text} disablePadding>
//               <ListItemButton>
//                 <ListItemIcon>
//                   {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
//                 </ListItemIcon>
//                 <ListItemText primary={text} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//       </Box>
//     </Drawer>
//   );
// };

// {
//   /* <div className="absolute bg-white flex w-screen justify-between p-8">
// {tabs?.map((t, index) => (
//   <div id={index}>{t}</div>
// ))}
//     </div> */
// }

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import HighlightIcon from "@mui/icons-material/Highlight";
import DescriptionIcon from "@mui/icons-material/Description";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const navList = [
  { heading: "Home", icon: <HomeIcon />, link: "/" },
  { heading: "Blog", icon: <DescriptionIcon />, link: "/blog" },
  { heading: "AVNSS Highlights", icon: <HighlightIcon />, link: "/highlights" },
  { heading: "Contact Us", icon: <ContactPhoneIcon />, link: "/contact" },
];

export const Sidebar = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  return (
    <div className="flex justify-end">
      <Button onMouseEnter={toggleDrawer(true)}>
        <div className="px-4 py-2 bg-green-300 rounded-full">
          <div className="flex gap-2">
            <div className="text-black">AVNSS Menu</div>
            <MenuIcon className="text-black w-8" />
          </div>
        </div>
      </Button>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <List className="flex justify-evenly w-screen">
            {navList?.map((item, index) => (
              <Link to={item?.link}>
                <ListItem key={index} disablePadding>
                  <ListItemButton>
                    <ListItemIcon className="-mr-4">{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.heading} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};
