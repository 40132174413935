import React from "react";
import hl1 from "../assets/hl1.jpg";
import hl2 from "../assets/hl2.jpg";

const Highlights = () => {
  return (
    <div className="w-full flex flex-col items-center px-28">
      <h1 className="text-4xl font-semibold">AVNSS Highlights</h1>
      <div className="place-items-start mt-4 max-w-[80vw]">
        <ol className="list-decimal">
          <li>Point 1</li>
          <li>Point 2</li>
          <li>Point 3</li>
          <li>Point 4</li>
          <li>Point 5</li>
          <li>Poincodfn kndpokn dkncn ndpk mcmd[pck nmd;,vckk c,t 6</li>
          <li>Pointcnkdj m 7</li>
          <li>Point cdnsnk mlk8</li>
          <li>Point 9</li>
          <li>Point 10</li>
          <li>Point 11</li>
          <li>Point 12</li>
          <li>Point 13</li>
          <li>Point 14</li>
          <li>Point 15</li>
        </ol>
      </div>
      <div className="flex justify-evenly gap-32 mt-16">
        <div>
          <img src={hl1} alt="hl1" className="h-60 bg-cover" />
        </div>
        <div>
          <img src={hl2} alt="hl2" className="h-60 bg-center" />
        </div>
      </div>
    </div>
  );
};

export default Highlights;
