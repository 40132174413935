import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import NavigationIcon from "@mui/icons-material/Navigation";

const Contact = () => {
  return (
    <div className="px-28">
      <div className="underline">NANDPURI MAIN ROAD</div>
      <div className="mt-2">
        <LocationOnIcon /> E-37, Nandpuri Main Road, Swejfarm, Sodala, Jaipur,
        302019
      </div>

      <div>
        <PhoneIcon /> +91-9314441466, +91-9828228282
      </div>

      <div>
        <EmailIcon /> anv.nandpuri@gmail.com
      </div>

      <div>
        <a
          href="https://maps.app.goo.gl/LzS7boUPLbHXtMfL8"
          target="_blank"
          rel="noreferrer"
        >
          <NavigationIcon /> Find us on Google Maps
        </a>
      </div>

      <div className="underline mt-8">BARKAT NAGAR</div>
      <div className="mt-2">
        <LocationOnIcon />
        158, Barkat Nagar, Tonk Phatak, Jaipur, 302015
      </div>
      <div>
        <PhoneIcon /> +91-9314441445
      </div>
      <div>
        <EmailIcon /> info@avnss.com
      </div>

      <div>
        <a
          href="https://maps.app.goo.gl/WdBnfb1DyaiLpZKL7"
          target="_blank"
          rel="noreferrer"
        >
          <NavigationIcon /> Find us on Google Maps
        </a>
      </div>

      <div className="text-center text-4xl italic">ENQUIRE NOW</div>

      <div className="flex justify-center">
        <iframe
          title="AVNSS Contact Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfG2OAFh_VFnMybjdA3CtfbBOZjY6U2dUN6p1mKIjcXqpuVaA/viewform?embedded=true"
          className="w-[50vw] h-[80vh]"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default Contact;
