import React from "react";

const SecretaryVision = () => {
  return (
    <div className="px-28 flex flex-col gap-8">
      <div>
        <div className="text-2xl font-semibold">Vision</div>
        To nurture young minds into confident, curious, and compassionate
        individuals who are equipped to thrive in the world.
      </div>
      <div>
        <div className="text-2xl font-semibold">Mission</div>
        Provide a safe, stimulating, and nurturing environment where children
        can explore, learn, and grow at their own pace. Foster a love of
        learning by encouraging curiosity, creativity, and critical thinking.
        Develop essential life skills, such as communication, collaboration,
        problem-solving, and self-regulation. Build strong foundations for
        future academic and personal success. Partner with parents to create a
        supportive and collaborative community.
      </div>
      <div>
        <div className="text-2xl font-semibold">Key values:</div>
        <ul>
          <li>Child-centered approach</li>
          <li>Respect for individual differences</li>
          <li>Play-based learning</li>
          <li>Positive reinforcement</li>
          <li>Ethical and moral values</li>
        </ul>
      </div>
    </div>
  );
};

export default SecretaryVision;
